<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model.trim="descripcion"
                label="Descripción"
                dense
                outlined
                :rules="
                  rules.required.concat([
                    rules.maxLength(descripcion, 50),
                    rules.requiredTrim(descripcion)
                  ])
                "
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="pb-0">
              <v-switch
                v-model="esAdicional"
                label="Es adicional"
                item-text="value"
                item-value="id"
                dense
                outlined
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="pb-0">
              <v-switch
                v-model="bonificacion"
                label="Es bonificación"
                item-text="value"
                item-value="id"
                dense
                outlined
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" class="pb-0">
              <v-switch
                v-model="esInteres"
                label="Es interés"
                item-text="value"
                item-value="id"
                dense
                outlined
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="pb-0">
              <v-switch
                v-model="percepcion"
                label="Es percepción IIBB"
                item-text="value"
                item-value="id"
                dense
                outlined
                @change="jurisdiccionSelected = null"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-autocomplete
                v-if="percepcion"
                v-model="jurisdiccionSelected"
                :items="jurisdiccionCombo"
                item-text="value"
                item-value="id"
                label="Jurisdicción IIBB"
                outlined
                clearable
                dense
                :rules="rules.required"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import EditImpuesto from "@/components/modules/cuotas/configuracion/EditImpuesto.vue";

export default {
  name: "EditConcepto",
  props: {
    conceptoObject: {
      type: Object,
      default: null
    }
  },
  components: {
    EditImpuesto
  },
  data: () => ({
    isFormValid: false,
    enums: enums,
    descripcion: "",
    formEditTitle: enums.titles.EDIT_CONCEPTO,
    rules: rules,
    esAdicional: false,
    bonificacion: false,
    esInteres: false,
    percepcion: false,
    jurisdiccionSelected: null,
    jurisdiccionCombo: [],
    nuevoConcepto: {},
    isNew: false
  }),
  created() {
    if (this.conceptoObject) {
      this.setConcepto();
    } else {
      this.isNew = true;
      this.newConcepto();
    }
    this.setJurisdiccionesSelect();
  },
  methods: {
    ...mapActions({
      getJurisdiccion: "afiliaciones/getJurisdiccionesIIBB",
      postConcepto: "configuracion/postConcepto",
      setAlert: "user/setAlert"
    }),
    async setJurisdiccionesSelect() {
      const response = await this.getJurisdiccion();
      this.jurisdiccionCombo = response;
    },
    async setConcepto() {
      this.concId = this.conceptoObject.concId;
      this.descripcion = this.conceptoObject.concNom;
      this.esAdicional = this.conceptoObject.esAdicional;
      this.bonificacion = this.conceptoObject.bonifica;
      this.esInteres = this.conceptoObject.esInteres;
      this.percepcion = this.conceptoObject.esPercIB;
      this.jurisdiccionSelected = this.conceptoObject.juribId;
    },
    async newConcepto() {
      this.formEditTitle = enums.titles.NUEVO_CONCEPTO;
    },
    openModalImpuestoSugerencia() {
      this.$emit("openModalImpuestoSugerencia");
    },

    async save() {
      let data = {
        concId: this.conceptoObject ? this.conceptoObject.concId : null,
        concNom: this.descripcion,
        esInteres: this.esInteres,
        esAdicional: this.esAdicional,
        bonifica: this.bonificacion,
        esPercIb: this.percepcion,
        jurIbId: this.jurisdiccionSelected
      };
      const res = await this.postConcepto(data);
      if (res.status === 200) {
        this.setAlert({
          type: "success",
          message: "Guardado concepto con éxito"
        });
        if (this.isNew) {
          this.nuevoConcepto = data;
          this.nuevoConcepto.concId = res.data.data;
          this.emitConcepto();
        }

        this.closeModal();
      }
    },

    closeModal() {
      this.$emit("closeAndReload");
    },

    emitConcepto() {
      this.openModalImpuestoSugerencia();
      this.$emit("emitConcepto", this.nuevoConcepto);
    }
  }
};
</script>

<style scoped>
::v-deep label {
  margin-bottom: 0;
}
</style>
